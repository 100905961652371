<template>
  <main>
    <div class="container">
      <form class="form-horizontal mb20" @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel">
              <div class="panel-heading">
                <h2 class="pageTitle">
                  メールアドレスの入力<span>pre-register</span>
                </h2>
              </div>
              <div class="panel-body">
                <div class="row mb10">
                  <div class="col-sm-8 col-sm-offset-2">
                    <p>
                      本登録用のURLを記載したメールをお送り致します。お使いのメールアドレスをご入力下さい。
                    </p>
                  </div>
                </div>
                <div class="form-group mb10 email">
                  <div class="col-sm-8 col-sm-offset-2 input-email">
                    <input v-model="email" type="text" name="email" class="form-control useicon" :placeholder="`${icon} メールアドレス`" autofocus>
                    <div class="help-block with-errors" />
                  </div>
                </div>
                <div class="col-sm-8 col-sm-offset-2 help-block with-errors">
                  <div v-if="errors.email" class="input-error">
                    {{ errors.email }}
                  </div>
                </div>
                <ul class="actionVertical clearfix">
                  <li>
                    <button type="submit" class="btn btn01">
                      上記アドレスに送信する
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import messageUtils from '../../shared/mixins/messageUtils'
import { getTokenPreRegisterApi } from '../../services/api'
import store from '../../store'
import { actions as actionsApp } from '../../store/modules/app'

export default {
  name: 'PreRegister',
  mixins: [messageUtils],
  data() {
    return {
      email: '',
      icon: '\uf0e0'
    }
  },
  mounted() {
    this.clearMessage()
  },
  methods: {
    async onSubmit() {
      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await getTokenPreRegisterApi({
        email: this.email.trim()
      })
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 201) {
        await this.$router.push({ name: 'MailSend' })
      } else {
        this.updateMessage(res.status, {
          email: Array.isArray(res.data.email) ? res.data.email : [res.data.email]
        })
      }
    }
  }
}
</script>

<style>

</style>

<style lang="scss" scoped>
.email {
  margin: 0 !important;
}
.input-email {
  height: 48px !important;
  padding: 0 !important;
}
</style>
